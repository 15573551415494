import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { Upload } from 'src/icons/upload';
import firebaseConfig from 'src/services/firebase/firebaseConfig';
import { getFilenameFromUrl } from 'src/utils/filename-from-url';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5 * KILO_BYTES_PER_BYTE * KILO_BYTES_PER_BYTE; //5MB
const DEFAULT_MAX_FILE_WIDTH_IN_PIXELS = 500;
const DEFAULT_MAX_FILE_HEIGHT_IN_PIXELS = 500;
const FIREBASE_BASE_PATH = 'https://firebasestorage.googleapis.com/v0/b/augmented-web-344514.appspot.com/o/';

const convertBytesToKB = (bytes) => bytes / KILO_BYTES_PER_BYTE;
const convertNestedObjectToArray = (nestedObj) => Object.keys(nestedObj).map((key) => nestedObj[key]);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  mx: 'auto',
  textAlign: 'center',
};

const FileUpload = ({
  updateFilesCallback,
  loadPictures = [''],
  maxFileSize = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  maxWidth = DEFAULT_MAX_FILE_WIDTH_IN_PIXELS,
  maxHeight = DEFAULT_MAX_FILE_HEIGHT_IN_PIXELS,
  ...otherProps
}) => {
  const inputFileRef = useRef(null);
  const [files, setFiles] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleUploadBtnClick = () => {
    inputFileRef?.current.click();
  };
  const addNewFiles = (newFiles) => {
    //TODO: feedback for too big of a file, there's no width/height check YET
    for (let file of newFiles) {
      console.log(file.size, maxFileSize);
      let isImageFile = file.type.split('/')[0] === 'image';
      if (file.size <= maxFileSize && isImageFile) {
        if (!otherProps.multiple) {
          console.log(file);
          return { file };
        }
        files[file.name] = file;
      } else {
        inputFileRef.current.value = null;
      }
    }
    return { ...files };
  };

  const callupdateFilesCallback = React.useCallback(
    (files) => {
      console.log(files);
      const filesAsArray = convertNestedObjectToArray(files);
      updateFilesCallback(filesAsArray);
      handleCloseModal();
    },
    [updateFilesCallback],
  );

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callupdateFilesCallback(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callupdateFilesCallback({ ...files });
  };

  React.useEffect(() => {
    const loadPics = (urlList) => {
      try {
        urlList.forEach((picUrl) => {
          if (!picUrl || picUrl == '') return;
          //fix storage references
          if (picUrl.startsWith('user-content/')) {
            picUrl =
              'https://firebasestorage.googleapis.com/v0/b/' +
              firebaseConfig.storageBucket +
              '/o/' +
              encodeURIComponent(picUrl) +
              '?alt=media';
          }
          const filename = getFilenameFromUrl(picUrl);
          const filesShallow = {};
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = () => {
            let blob = xhr.response;
            filesShallow[filename] = new File([blob], filename);
            setFiles(filesShallow);
            callupdateFilesCallback(filesShallow);
          };
          xhr.open('GET', picUrl);
          xhr.send();
        });
      } catch (e) {
        console.log('error on picLoader', e);
      }
    };
    if (typeof loadPictures == 'string') {
      loadPics([loadPictures]);
    } else if (typeof loadPictures == 'object') {
      loadPics(loadPictures);
    }
  }, [loadPictures, callupdateFilesCallback]);

  return (
    <React.Fragment>
      {Object.keys(files).length == 0 ? (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 0 }}>
          <img
            style={{
              marginRight: 16,
              width: '200px',
            }}
            src={'/static/images/default.jpg'}
          />
          <Stack direction='column' spacing={2} sx={{ maxWidth: '96%' }}>
            <Button onClick={handleOpenModal} variant='contained' startIcon={<OpenInNewIcon />}>
              Carregar imagem
            </Button>
          </Stack>
        </Stack>
      ) : (
        <>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            return (
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 0 }} key={fileName}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`file preview ${index}`}
                  style={{
                    marginRight: 16,
                    maxWidth: maxWidth + 'px',
                  }}
                />
                <Stack direction='column' spacing={2} sx={{ maxWidth: '96%' }}>
                  <Button onClick={handleOpenModal} variant='contained' startIcon={<OpenInNewIcon />}>
                    Carregar imagem
                  </Button>
                  <Button
                    color='error'
                    variant='contained'
                    onClick={() => removeFile(fileName)}
                    startIcon={<DeleteIcon />}
                  >
                    Remover imagem
                  </Button>
                </Stack>
              </Stack>
            );
          })}
        </>
      )}
      <Modal hideBackdrop open={openModal} onClose={handleOpenModal}>
        <Stack sx={{ ...style }} direction='column' justifyContent='center' alignItems='center'>
          <input
            ref={inputFileRef}
            onChange={handleNewFileUpload}
            hidden
            accept='image/*'
            type='file'
            title=''
            {...otherProps}
          />
          <Typography variant='h5' component='h2'>
            Enviar imagem
          </Typography>
          <IconButton color='primary' aria-label='upload picture' component='label' onClick={handleUploadBtnClick}>
            <Upload />
          </IconButton>
          <Typography variant='p'>Faça upload da imagem</Typography>
          <Typography mt={2} variant='caption'>
            Formatos aceitos .png .jpeg .jpg
          </Typography>
          <Typography variant='caption'>
            Tamanho Maximo {maxWidth} x {maxHeight} pixels
          </Typography>
          <Typography variant='caption'>Limite {convertBytesToKB(maxFileSize)} kb</Typography>
          <Stack mt={3} direction='row' justifyContent='center' alignItems='center' spacing={2}>
            <Button onClick={handleUploadBtnClick} variant='contained' startIcon={<OpenInNewIcon />}>
              Enviar nova imagem
            </Button>
            <Button onClick={handleCloseModal} variant='contained' startIcon={<CloseIcon />}>
              Fechar
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </React.Fragment>
  );
};

FileUpload.propTypes = {
  updateFilesCallback: PropTypes.func.isRequired,
  loadPictures: PropTypes.array,
  maxFileSize: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default FileUpload;
