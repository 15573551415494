import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import React, { Suspense } from 'react';
import FileUpload from 'src/components/parts/fileUpload';
import { useFirebaseAuth } from 'src/hooks';
import { Upload } from 'src/icons/upload';
import useSWR from 'swr';

export const PerfilCadastro = () => {
  // eslint-disable-next-line no-unused-vars
  const [aguardeCarregando, setAguardeCarregando] = React.useState('Carregando listagem...');
  const { fetcher, api } = useFirebaseAuth();
  const { data, mutate } = useSWR('/api/v1/usuarios/dados-pessoais', fetcher, { suspense: true });

  const [dadosPessoais, setDadosPessoais] = React.useState(data);

  console.log('Dados pessoais: ', dadosPessoais);

  const [dadosEndereco, setDadosEndereco] = React.useState(
    dadosPessoais.endereco || {
      endereco: '',
      complemento: '',
      numero: '',
      bairro: '',
      cidadeNome: '',
      estadoSigla: '',
      cep: '',
    },
  );
  console.log('Endereço: ', dadosEndereco);

  const getCEP = async (e) => {
    const cep = e.target.value.replace(/\D/g, '');

    const { data } = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
    console.log('CEP: ', data);

    setDadosEndereco({
      ...dadosEndereco,
      endereco: data.logradouro,
      bairro: data.bairro,
      cidadeNome: data.localidade,
      estadoSigla: data.uf,
      cep: data.cep,
    });
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [date, setDate] = React.useState(dadosPessoais.dataNascimento || new Date());

  const handleChangeCampo = (e) => {
    setDadosPessoais({ ...dadosPessoais, [e.target.name]: e.target.value });
  };
  const handleChangeTel = (e) => {
    if (e.target.value > 99999999999) {
      return;
    } else {
      setDadosPessoais({ ...dadosPessoais, [e.target.name]: e.target.value });
    }
  };
  const handleChangeCampoEnd = (e) => {
    setDadosEndereco({ ...dadosEndereco, [e.target.name]: e.target.value });
  };

  const Salvar = async () => {
    const payloadContato = {
      email: dadosPessoais.email ?? null,
      telefone: dadosPessoais.telefone ?? null,
    };

    const payloadPessoal = {
      nome: dadosPessoais.nome ?? null,
      dataNascimento: date ?? null,
    };

    await api.patch(`/api/v1/usuarios/contato`, payloadContato);
    await api.patch(`/api/v1/usuarios/dados-pessoais`, payloadPessoal);

    mutate();
  };

  const SalvarEndereco = async () => {
    const payloadEndereco = {
      cep: dadosEndereco.cep ?? null,
      endereco: dadosEndereco.endereco ?? null,
      numero: dadosEndereco.numero ?? null,
      complemento: dadosEndereco.complemento ?? null,
      bairro: dadosEndereco.bairro ?? null,
      cidadeNome: dadosEndereco.cidadeNome ?? null,
      estadoSigla: dadosEndereco.estadoSigla ?? null,
    };

    console.log('Payload Endereço: ', payloadEndereco);

    await api.patch(`/api/v1/usuarios/endereco`, payloadEndereco);

    mutate();
  };

  return (
    <>
      <Suspense fallback={<aguardeCarregando />}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList variant='fullWidth' onChange={handleChange} aria-label='lab API tabs example'>
                <Tab icon={<AccountCircleOutlinedIcon />} iconPosition='start' label='Dados' value='1' />
                <Tab icon={<LocationOnOutlinedIcon />} iconPosition='start' label='Endereço' value='2' />
              </TabList>
            </Box>
            <TabPanel value='1'>
              <Box className='displayendflex'>
                <Button startIcon={<SaveOutlinedIcon />} variant='contained' onClick={Salvar} sx={{ mt: 1 }}>
                  Salvar alterações
                </Button>
              </Box>
              <Box>
                <Typography variant='h5'>Foto de perfil</Typography>
              </Box>
              <Box sx={{ mt: 2 }} className='displayflexcenter'>
                <FileUpload maxHeight={100} maxWidth={100} />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='h5'>Dados de usuário</Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>Nome</Typography>
                <TextField
                  fullWidth
                  name='nome'
                  value={dadosPessoais.nome}
                  onChange={handleChangeCampo}
                  component='form'
                  placeholder='Nome Sobrenome'
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>CPF</Typography>
                <TextField
                  fullWidth
                  name='documento'
                  value={dadosPessoais.documento}
                  onChange={handleChangeCampo}
                  component='form'
                  disabled
                  id='outlined-disabled'
                  placeholder='000.000.000-00'
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>Data de nascimento</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={date}
                    mask='__-__-____'
                    inputFormat='dd/MM/yyyy'
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='h5'>Dados de contato</Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='p'>E-mail</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      name='email'
                      value={dadosPessoais.email}
                      onChange={handleChangeCampo}
                      id='outlined-disabled'
                      placeholder='Email@email.com'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='p'>Celular</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      name='telefone'
                      value={dadosPessoais.telefone}
                      onChange={handleChangeTel}
                      type='phone'
                      InputProps={{ inputProps: { min: 0, max: 11 } }}
                      id='outlined-disabled'
                      placeholder='(00) 0000-0000'
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='h5'>Documentos</Typography>
              </Box>

              <Box>
                <Box sx={{ width: '49%', float: 'left', marginLeft: '1%', mt: 1 }}>
                  <Box className='borda displayflexcenterwrap radius' sx={{ p: 2 }}>
                    <Box>
                      <Box>
                        <Typography variant='p'>
                          <strong>RG Frente</strong>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Button variant='contained' sx={{ m: 0.5 }}>
                        <EditIcon />
                      </Button>
                      <Button variant='contained' color='error' sx={{ m: 0.5 }}>
                        <DeleteIcon />
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ width: '49%', float: 'left', marginLeft: '1%', mt: 1 }}>
                  <Box className='borda displayflexcenterwrap radius' sx={{ p: 2 }}>
                    <Box>
                      <Box>
                        <Typography variant='p'>
                          <strong>RG Verso</strong>
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Button variant='contained' sx={{ m: 0.5 }}>
                        <EditIcon />
                      </Button>
                      <Button variant='contained' color='error' sx={{ m: 0.5 }}>
                        <DeleteIcon />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
              >
                <DialogTitle id='scroll-dialog-title'>Subscribe</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                  <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                    <Box>
                      <Box sx={{ textAlign: 'center' }}>
                        <Box sx={{ width: '100%', p: 5, display: 'grid' }}>
                          <Box>
                            <IconButton color='primary' aria-label='upload picture' component='label'>
                              <input hidden accept='image/*' type='file' data-image='banner' />
                              <Upload />
                            </IconButton>
                          </Box>
                          <Typography sx={{ m: 1 }}>Faça upload da imagem</Typography>
                          <Typography sx={{ m: 1 }} variant='caption'>
                            Proporção da imagem 16:9
                            <br />
                            <strong>Tamanhos sugeridos</strong>
                            <br />
                            1920x1080 pixels | 1280x720 pixels
                            <br />
                            Formatos aceitos .png .jpeg | Limite 5mb
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleClose}>Subscribe</Button>
                </DialogActions>
              </Dialog>
            </TabPanel>
            <TabPanel value='2'>
              <Box className='displayendflex'>
                <Button startIcon={<SaveOutlinedIcon />} onClick={SalvarEndereco} variant='contained' sx={{ mt: 1 }}>
                  Salvar alterações
                </Button>
              </Box>

              <Box>
                <Typography variant='h5'>Endereço</Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>CEP</Typography>
                <TextField
                  fullWidth
                  value={dadosEndereco.cep}
                  onChange={(e) => setDadosEndereco({ ...dadosEndereco, cep: e.target.value })}
                  onBlur={getCEP}
                  component='form'
                  placeholder='00000-000'
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>Endereço</Typography>
                <TextField
                  fullWidth
                  component='form'
                  id='outlined-disabled'
                  name='endereco'
                  value={dadosEndereco.endereco}
                  onChange={handleChangeCampoEnd}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant='p'>Número</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      id='outlined-disabled'
                      name='numero'
                      value={dadosEndereco.numero}
                      onChange={handleChangeCampoEnd}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='p'>Complemento</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      id='outlined-disabled'
                      name='complemento'
                      value={dadosEndereco.complemento}
                      onChange={handleChangeCampoEnd}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant='p'>Bairro</Typography>
                <TextField
                  fullWidth
                  component='form'
                  id='outlined-disabled'
                  name='bairro'
                  value={dadosEndereco.bairro}
                  onChange={handleChangeCampoEnd}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Typography variant='p'>Cidade</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      id='outlined-disabled'
                      name='cidadeNome'
                      value={dadosEndereco.cidadeNome}
                      onChange={handleChangeCampoEnd}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant='p'>UF</Typography>
                    <TextField
                      fullWidth
                      component='form'
                      id='outlined-disabled'
                      name='estadoSigla'
                      value={dadosEndereco.estadoSigla}
                      onChange={handleChangeCampoEnd}
                    />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Suspense>
    </>
  );
};

PerfilCadastro.propTypes = {};

export default PerfilCadastro;
